<template>
  <div id="stories">
    <section class="relative" :class="{ 'pt_head': !($route.name && $route.name.includes('Moment')) }">
      <div v-show="!($route.name && $route.name.includes('Moment'))">
        <div class="fluid_content_container mx-auto pt-20 pb-8">
          <h1 class="heading text-5xl lg:text-7xl px-3 sm:px-0">
            <div class="font-bold text-blue-900">{{ $store.getters.texts("stories.title1") }}</div>
            <div class="text-dilo-blue font-thin -mt-2">{{ $store.getters.texts("stories.title2") }}</div>
          </h1>

          <div class="ml-20 mt-6 text-blue-900">
            {{ $store.getters.texts("stories.intro") }}
          </div>

          <div class="moments mx-auto mt-16" v-show="init">
            <template v-for="(content, index) in $store.state.response">
              <div class="moment flex" :id="'moment-' + index">
                <div
                    :class="'square w-1/2 cursor-pointer overflow-hidden z-20 bg-contain square-' + index"
                    :style="'background-color:' + content.styles.bgcolor + '; background-image:url(' + content.thumbnail + '); ' + (content.styles.color ? 'color:' + content.styles.color : '')"
                    @click="simpleFade($event, index)"
                    @mouseover="rollover($event, index)"
                    @mouseout="rollout($event, index)"
                >
                  <div
                      v-if="content.styles.title !== false"
                      class="text-2xl md:text-4xl p-4 absolute top-0 left-0 bottom-0 right-0 flex flex-col justify-end"
                      v-html="content.styles.title ? content.styles.title : content.name"
                  >
                  </div>
                </div>
                <div class="aside w-1/2 pl-3 md:pl-5 lg:pl-7">
                  <div class="category flag md:-mb-2 text-sm">
                    <div v-html="content.category"></div>
                    <div class="after"></div>
                  </div>
                  <div class="number flex pl-2">
                    <div class="font-thin">#</div>
                    <div>{{ content.fieldnumber }}</div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="more-to-come">
            <div class="font-thin ml-2">>>></div>
            <div>{{ $store.getters.texts("stories.more1") }}</div>
            <div>{{ $store.getters.texts("stories.more2") }}</div>
            <div>{{ $store.getters.texts("stories.more3") }}</div>
          </div>


          <hr class="border-white">

          <a :href="$store.getters.texts('general.diloLink')" class="moment flex mt-12" :id="'moment-' + $store.state.response.length" target="_blank">
            <div
                class="square square-home w-1/2 cursor-pointer overflow-hidden z-20 bg-contain"
                style="background-image: url('/application/files/6816/1364/9357/dilo_logo.svg');"
                @mouseover="rollover($event, $store.state.response.length)"
                @mouseout="rollout($event, $store.state.response.length)"
            >
              <div class="text-2xl md:text-4xl p-4 absolute top-0 left-0 bottom-0 right-0 flex flex-col justify-end">
              </div>
            </div>
            <div class="aside w-1/2 pl-3 md:pl-5 lg:pl-7">
              <div class="category flag md:-mb-2 text-sm">
                <div>www.dilo.eu</div>
                <div class="after"></div>
              </div>
              <div class="number flex pl-2">
                #GO
              </div>
            </div>
          </a>

          <hr class="border-white">

        </div>
        <div v-if="lastContent" class="go_to_bottom btn btn_blue fixed cursor-pointer" @click="scrollToLast">
          <i class="far fa-arrow-down"></i>
          <div class="text">{{ $store.getters.texts("stories.latest") + lastContent.fieldnumber }}</div>
        </div>
      </div>

      <Footer v-if="$route.name && $route.name.includes('Stories')"></Footer>

      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ajaxifyForm } from "@/assets/js/form.ts";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

gsap.registerPlugin(CSSPlugin, ScrollTrigger);

export default {
  name: "Stories",
  components: { Footer, Header },
  data() {
    return {
      init: false,
      showForm: false,
    };
  },
  computed: {
    lastContent() {
      return this.$store.state.response[this.$store.state.response.length - 1];
    },
    momentContainerStyle() {
      return "background-color: " + this.$store.state.bgColor + ";";
    },
  },
  methods: {
    initGsap() {
      let tls = [];
      for (let i = 0; i <= this.$store.state.response.length; i++) {
        let moment = "#moment-" + i;
        let square = moment + " .square";
        let aside = moment + " .aside";
        gsap.set(square, {
          x: -500,
          y: 100,
          opacity: "0%",
          rotation: -90
        });
        gsap.set(aside, {
          opacity: "0%",
          x: -150
        });

        tls[i] = gsap.timeline({
          scrollTrigger: {
            trigger: moment,
            toggleActions: "restart none none reset",
            markers: false
          }
        });
        tls[i]
            .to(square, {
              x: 0,
              y: 0,
              opacity: "100%",
              rotation: 0,
              transformOrigin: "30% -30%",
              duration: .5,
              ease: "back.out"
            })
            .to(aside, {
              x: 0,
              opacity: "100%",
              duration: .4,
              ease: "back.out",
              delay: -.3
            });
      }
      gsap.to(".go_to_bottom", {
        scrollTrigger: {
          trigger: ".moments",
          start: "top 30%",
          end: "top 10%"
        },
        duration: .5,
        y: 100,
      });
      this.showForm = true;
      gsap.from(".more-to-come div", {
        scrollTrigger: {
          trigger: ".moments",
          toggleActions: "restart none none reverse",
          start: "bottom bottom",
          end: "bottom top",
          markers: false,
        },
        x: -300,
        duration: 1,
        stagger: .3,
        ease: "back.out"
      });
      this.init = true;
    },
    getRotation(element) {
      let angle = 0;
      let el = element.parentElement;
      if (el !== null) {
        let st = window.getComputedStyle(el, null);
        let tr = st.getPropertyValue("-webkit-transform") ||
                 st.getPropertyValue("-moz-transform") ||
                 st.getPropertyValue("-ms-transform") ||
                 st.getPropertyValue("-o-transform") ||
                 st.getPropertyValue("transform");
        if (typeof tr === "string" && tr !== "none") {
          let values = tr.split("(")[1].split(")")[0].split(",");
          let a = parseFloat(values[0]);
          let b = parseFloat(values[1]);
          angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        }
      }
      return angle;
    },
    rollover(event, index) {
      if (window.innerWidth > 768 && window.innerHeight > 768) {
        let moment = "#moment-" + index;
        let square = moment + " .square";
        gsap.to(square, {
          scale: 1.1,
          rotation: -7,
          transformOrigin: "50% 50%",
          duration: 0.2
        });
      }
    },
    rollout(event, index) {
      let moment = "#moment-" + index;
      let square = moment + " .square";
      gsap.to(square, {
        scale: 1,
        rotation: 0,
        transformOrigin: "50% 50%",
        duration: 0.2
      });
    },
    scrollToLast() {
      document.getElementById("moment-" + (this.$store.state.response.length - 2)).scrollIntoView({ behavior: "smooth" });
    },
    simpleFade(event, index) {
      this.$store.commit("showCurtain", true);
      this.$store.commit("activateLoading", index);
      let momentTile = event.target;
      while (!momentTile.classList.contains("moment")) {
        momentTile = momentTile.parentElement;
        if (momentTile.id === "stories") break;
      }

      let that = this;
      setTimeout(function() {
        that.$router.push({
          name: "Moment",
          params: { handle: that.$store.state.response[index].handle, lang: that.$store.state.language === "en" ? "en" : "" }
        });
      }, 200);

      gsap.fromTo("#curtain", {
        x: momentTile.getBoundingClientRect().left, y: momentTile.getBoundingClientRect().top,
        opacity: "0%",
        width: momentTile.offsetWidth, height: momentTile.offsetHeight,
        rotation: this.getRotation(momentTile),
        backgroundColor: this.$store.state.response[index].styles.bgcolor,
        alpha: 80
      }, {
        x: 0, y: 0,
        width: window.innerWidth - 20, height: window.innerHeight,
        rotation: 0,
        opacity: "100%",
        duration: .5,
        ease: "strong.out",
        onComplete: this.$store.commit, onCompleteParams: [ "showCurtain", false ]
      });
    },
    style(styles) {
      let result = "";
      if (styles) {
        if (styles.rotation) {
          result += `-webkit-transform: rotate(${styles.rotation}deg);`;
          result += `-ms-transform: rotate(${styles.rotation}deg);`;
          result += `transform: rotate(${styles.rotation}deg);`;
        }
        if (styles.yOffset) result += `margin-top: ${styles.yOffset}px;`;
        if (styles.xOffset) result += `margin-left: ${styles.xOffset}px;`;
      }
      return result;
    }
  },
  watch: {
    "$store.state.response"(nv) {
      if (nv.length) this.$nextTick(this.initGsap);
    },
    "$route"(nv) {
      this.init = false;
      if (nv.name === "Stories") this.$nextTick(this.initGsap);
    }
  },
  mounted() {
    if (this.$store.state.response.length) this.$nextTick(this.initGsap);
    this.$nextTick(() => ajaxifyForm("seventy_subscribe_form"));
  },
};
</script>
